import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from 'react';

import { classNames } from '@/utils/ui';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
  removeBaseCss?: boolean;
}
export const BASE_BUTTON =
  'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm';
export function Button(props: PropsWithChildren<ButtonProps>) {
  const { disabled, isLoading, removeBaseCss, ...buttonProps } = props;
  return (
    <button
      disabled={disabled || isLoading}
      type="button"
      {...buttonProps}
      className={classNames(
        removeBaseCss ? '' : BASE_BUTTON,
        props.className ||
          classNames(
            'text-white',
            props.disabled ? 'bg-indigo-300' : 'bg-indigo-600',
            'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          )
      )}
    >
      <div className="flex flex-row items-center justify-center">
        {props.isLoading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {props.children}
      </div>
    </button>
  );
}
