import {
  useRef,
  Fragment,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
} from 'react';

import { useClient } from '@appmonet/jsonapi-react';
import { useAuth } from '@clerk/nextjs';
import { Transition, Dialog } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/router';
import { ActionMeta, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import Option from 'react-select/dist/declarations/src/components/Option';

import { UserInfo } from '@/utils/auth';
import { getSessionCookie } from '@/utils/session';

import { Button } from './button';

const ChangeAccount = ({
  showDialog,
  setShowDialog,
  user,
}: {
  showDialog: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  user?: UserInfo;
}) => {
  const client = useClient();
  const route = useRouter();
  const cancelButtonRef = useRef(null);
  const [account, setAccount] = useState<any>({
    value: user?.account?.id?.toString(),
    label: user?.account?.name,
  });
  const { sessionId } = useAuth();
  const [isAccountChanging, setIsAccountChanging] = useState(false);

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: any[]) => void) => {
      if (inputValue.length > 0) {
        client
          .fetch(
            [
              'private-deal-accounts',
              {
                filter: {
                  search: inputValue,
                },
              },
            ],
            {
              headers: {
                clerk_jwt: getSessionCookie() || '',
                clerk_session: sessionId || '',
              },
            }
          )
          .then((response) => {
            callback(
              response.data?.map((data: any) => {
                return { value: data.id, label: data.name };
              })
            );
          });
      } else {
        callback([]);
      }
    },
    [client, sessionId]
  );

  const handleChangeAccount = useCallback(() => {
    setIsAccountChanging(true);
    client
      .mutate(
        ['private-deal-users', user?.id],
        {
          'private-deal-account': {
            id: account.value,
          },
        },
        {
          headers: {
            clerk_jwt: getSessionCookie() || '',
            clerk_session: sessionId || '',
          },
        }
      )
      .then((_response) => {
        if (route.pathname === '/dashboard/ad-groups/mockups') {
          route.reload();
        } else {
          route.push('/dashboard', undefined, { shallow: true }).finally(() => {
            route.reload();
          });
        }
      });
  }, [account.value, client, route, sessionId, user?.id]);

  const onChange = useCallback(
    (newValue: SingleValue<any>, _actionMeta: ActionMeta<typeof Option>) => {
      setAccount(newValue);
    },
    []
  );

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-200"
        initialFocus={cancelButtonRef}
        onClose={setShowDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center pt-16 p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserGroupIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left grow">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Change Account
                    </Dialog.Title>
                    <div className="mt-2">
                      <AsyncSelect
                        menuPortalTarget={
                          typeof document !== 'undefined' ? document.body : null
                        }
                        menuPosition={'fixed'}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 999999,
                          }),
                        }}
                        placeholder="Select Account"
                        cacheOptions
                        defaultOptions
                        isSearchable={true}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        isMulti={false}
                        isClearable={false}
                        value={account}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                  <Button
                    isLoading={isAccountChanging}
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                    onClick={() => {
                      handleChangeAccount();
                    }}
                  >
                    Change
                  </Button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowDialog(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { ChangeAccount };
