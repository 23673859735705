import { ReactNode } from 'react';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
};

const Main = (props: IMainProps) => (
  <div className="antialiased w-full text-gray-700">
    <p>{props.meta}</p>
    <div className="text-xl content">{props.children}</div>
  </div>
);

export { Main };
