/* This example requires Tailwind CSS v2.0+ */
import { PropsWithChildren } from 'react';

import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';

export interface BreadcrumbInfo {
  title: string;
  href?: string;
  hardRefresh?: boolean;
}

function BreadcrumbLink({
  href,
  children,
  hardRefresh,
}: PropsWithChildren<{ href: string; hardRefresh?: boolean }>) {
  if (hardRefresh) {
    return <a href={href}>{children}</a>;
  }

  return <Link href={href}>{children}</Link>;
}

export default function Breadcrumb({ info }: { info: Array<BreadcrumbInfo> }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link href="/dashboard">
              <div className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </div>
            </Link>
          </div>
        </li>
        {info.map((page) => (
          <li key={page.title}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {page.href ? (
                <BreadcrumbLink hardRefresh={page.hardRefresh} href={page.href}>
                  <p className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-500 cursor-pointer">
                    {page.title}
                  </p>
                </BreadcrumbLink>
              ) : (
                <p className="ml-4 text-base font-medium text-gray-300">
                  {page.title}
                </p>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
