import Head from 'next/head';

import { AccountInfo } from '@/utils/auth';

const Meta = ({
  title,
  links,
  account,
}: {
  title?: String;
  links?: Array<{ rel: string; href: string }>;
  account?: AccountInfo;
}) => {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <meta name="robots" content="noindex,nofollow" />
        <link
          rel="apple-touch-icon"
          href="https://adaptmx.com/wp-content/uploads/2021/10/cropped-favicon-96x96-1-32x32-1.png"
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://adaptmx.com/wp-content/uploads/2021/10/cropped-favicon-96x96-1-32x32-1.png"
          key="icon32"
        />
        {links?.map((link, index) => (
          <link key={index} rel={link.rel} href={link.href} />
        ))}
        <title>
          {title}
          {account?.name ? ` | ${account.name}` : ''}
        </title>
      </Head>
    </>
  );
};

export { Meta };
