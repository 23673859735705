import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { useClerk } from '@clerk/nextjs';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';

import Breadcrumb, { BreadcrumbInfo } from '@/components/Breadcrumb';
import { UserInfo } from '@/utils/auth';

import { ChangeAccount } from './ChangeAccount';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const routes = [
  {
    name: 'Reporting',
    href: '/dashboard/reporting',
  },
  {
    name: 'Ad Groups',
    href: '/dashboard/ad-groups',
  },
  {
    name: 'Tags',
    href: '/dashboard/tags',
  },
  {
    name: 'Admin',
    href: '/dashboard/admin/campaigns',
    admin: true,
  },
];

const routeInPath = (
  route: {
    name: string;
    href: string;
    path?: string;
  },
  path: string
) => {
  return path.includes(`${route.href}${route.path || ''}`);
};

export interface HeaderProps {
  wrapperClass?: string;
  user?: UserInfo;
  children: ReactNode;
  showOnlyLogo?: boolean;
  header?: ReactNode;
  containerStyle?: string;
  disable?: boolean;
  breadcrumbs?: Array<BreadcrumbInfo>;
}

const ProfileInfo = ({
  firstName,
  lastName,
  email,
  account,
}: {
  firstName: string;
  lastName: string;
  email: string;
  account?: string;
}) => {
  const userInitials = firstName[0]!.toUpperCase() + lastName[0]!.toUpperCase();
  return (
    <div className="flex justify-start">
      <div className="pr-2 ml-2 mt-2 mb-2 grid items-center">
        <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
          <span className="text-sm font-medium leading-none text-white">
            {userInitials}
          </span>
        </span>
      </div>
      <div className="mt-2 mb-2 mr-2 grid items-center">
        <div className="text-white text-sm">{email}</div>
        {account ? (
          <div className="text-white text-xs text-left">{account}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const MenuButton = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  return (
    <button
      ref={ref}
      onClick={props.onClick}
      className={`text-white hover:bg-indigo-700 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium ${props.className}`}
    >
      {props.children}
    </button>
  );
});

const redirectTo = (
  path: string,
  router: NextRouter,
  redirect?: string | null
) => {
  if (redirect) {
    return router.push(
      `${
        process.env.NEXT_PUBLIC_CLERK_ACCOUNTS_DOMAIN
      }/${path}?redirect_url=${encodeURIComponent(redirect)}`
    );
  }
  return router.push('/dashboard');
};

const LoginSignUpButtons = ({
  router,
  redirect,
}: {
  router: NextRouter;
  redirect?: string | null;
}) => {
  return (
    <div className="flex row">
      <MenuButton
        className="bg-white text-indigo-700 hover:bg-slate-50 mr-4"
        onClick={() => redirectTo('sign-in', router, redirect)}
      >
        Sign in
      </MenuButton>
      <MenuButton
        className="bg-indigo-500 hover:bg-indigo-6500"
        onClick={() => redirectTo('sign-up', router, redirect)}
      >
        Sign up
      </MenuButton>
    </div>
  );
};

const Header = ({
  children,
  wrapperClass,
  header,
  user,
  containerStyle,
  showOnlyLogo,
  breadcrumbs,
  disable,
}: HeaderProps) => {
  const [changeAccountDialog, setChangeAccountDialog] = useState(false);
  const { signOut } = useClerk();
  const router = useRouter();
  const [redirect, setRedirect] = useState<string | null>(null);
  const isUserInfoAvailable = user?.firstName && user?.lastName && user?.email;
  useEffect(() => {
    setRedirect(window.location.href);
  }, []);

  const MenuButtonsDesktop = () => {
    return isUserInfoAvailable ? (
      <Menu.Button className="hover:bg-indigo-501 hover:bg-opacity-75 rounded-md padding">
        <ProfileInfo
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
          account={user?.account?.name}
        />
      </Menu.Button>
    ) : (
      <LoginSignUpButtons redirect={redirect} router={router} />
    );
  };

  const MenuButtonsMobile = () => {
    return isUserInfoAvailable ? (
      <>
        <MenuButton
          onClick={() => {
            router.push('/settings');
          }}
          className="w-full text-left text-base"
        >
          Settings
        </MenuButton>
        <MenuButton
          onClick={() => {
            signOut().then(() => {
              router.replace('/');
            });
          }}
          className="w-full text-left text-base"
        >
          Sign Out
        </MenuButton>
      </>
    ) : (
      <>
        <MenuButton
          onClick={() => redirectTo('sign-in', router, redirect)}
          className="w-full text-left text-base"
        >
          Sign in
        </MenuButton>
        <MenuButton
          onClick={() => redirectTo('sign-up', router, redirect)}
          className="w-full text-left text-base"
        >
          Sign up
        </MenuButton>
      </>
    );
  };
  return (
    <>
      <ChangeAccount
        showDialog={changeAccountDialog}
        setShowDialog={setChangeAccountDialog}
        user={user}
      />
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32 print:px-14">
          <Popover className="relative">
            <Popover.Group as="nav" className="bg-indigo-600  lg:border-none">
              <Disclosure as="nav" className="bg-indigo-600  lg:border-none">
                {({ open }) => (
                  <>
                    <div className="max-w-9xl mx-auto px-2 sm:px-4 lg:px-8">
                      <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25 print:pt-8">
                        <div className="px-2 flex items-center lg:px-0">
                          <div className="flex-shrink-0 cursor-pointer">
                            <Link
                              href={
                                user
                                  ? '/dashboard'
                                  : (process.env
                                      .NEXT_PUBLIC_HOME_PAGE_URL as string)
                              }
                            >
                              <div>
                                <img
                                  className="object-cover h-[35px]"
                                  src="//adaptmx.com/wp-content/uploads/2020/07/AdaptMX-logo-Oriz-white-bg-84.png"
                                  alt="AdaptMx"
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="hidden lg:block lg:ml-10">
                            <div className="flex space-x-4">
                              {user?.account?.id &&
                                !disable &&
                                routes
                                  ?.filter((route) => {
                                    return route.name === 'Reporting'
                                      ? user.hasDeals
                                      : true;
                                  })
                                  .filter((route) => {
                                    return route.name === 'Admin'
                                      ? (route.admin && user?.admin) || false
                                      : true;
                                  })
                                  .map((item) => (
                                    <Link
                                      key={item.name}
                                      href={item.href}
                                      passHref
                                    >
                                      <div
                                        className={classNames(
                                          routeInPath(item, router.pathname)
                                            ? 'bg-indigo-700 text-white'
                                            : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                          'rounded-md py-2 px-3 text-sm font-medium hover:cursor-pointer'
                                        )}
                                        aria-current={
                                          routeInPath(item, router.pathname)
                                            ? 'page'
                                            : undefined
                                        }
                                      >
                                        {item.name}
                                      </div>
                                    </Link>
                                  ))}
                            </div>
                          </div>
                        </div>
                        <div className="items-center justify-between hidden lg:flex">
                          <Menu
                            as="div"
                            className="relative text-left hidden lg:block"
                          >
                            {!showOnlyLogo && !disable && (
                              <MenuButtonsDesktop />
                            )}
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-indigo-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {user?.admin && (
                                  <Menu.Item>
                                    <MenuButton
                                      onClick={() => {
                                        setChangeAccountDialog(true);
                                      }}
                                      className="w-full text-left hover:bg-indigo-700"
                                    >
                                      Change Account
                                    </MenuButton>
                                  </Menu.Item>
                                )}
                                <Menu.Item>
                                  <MenuButton
                                    onClick={() => {
                                      router.push('/settings');
                                    }}
                                    className="w-full text-left hover:bg-indigo-700"
                                  >
                                    Settings
                                  </MenuButton>
                                </Menu.Item>
                                <Menu.Item>
                                  <MenuButton
                                    onClick={() => {
                                      signOut();
                                      router.replace('/dashboard');
                                    }}
                                    className="w-full text-left hover:bg-indigo-700"
                                  >
                                    Sign Out
                                  </MenuButton>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>

                        <div className="flex lg:hidden print:hidden">
                          {/* Mobile menu button */}
                          <Disclosure.Button className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                              <XMarkIcon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <Bars3Icon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </Disclosure.Button>
                        </div>
                      </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                      <div className="px-2 pt-2 pb-3 space-y-1 border-b border-indigo-300 border-opacity-25">
                        {user?.account?.id &&
                          !disable &&
                          routes
                            ?.filter((route) => {
                              return route.name === 'Reporting'
                                ? user.hasDeals
                                : true;
                            })
                            .filter((route) => {
                              return route.name === 'Admin'
                                ? (route.admin && user?.admin) || false
                                : true;
                            })
                            .map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className={classNames(
                                  routeInPath(item, router.pathname)
                                    ? 'bg-indigo-700 text-white'
                                    : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                  'block rounded-md py-2 px-3 text-base font-medium'
                                )}
                                aria-current={
                                  routeInPath(item, router.pathname)
                                    ? 'page'
                                    : undefined
                                }
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                        {user?.admin && (
                          <div>
                            <MenuButton
                              onClick={() => {
                                setChangeAccountDialog(true);
                              }}
                              className="w-full text-left text-base"
                            >
                              Change Account
                            </MenuButton>
                          </div>
                        )}
                        <div>
                          {!showOnlyLogo && !disable && <MenuButtonsMobile />}
                        </div>
                      </div>
                      {isUserInfoAvailable && !disable && (
                        <div className="pl-3 pt-3">
                          <ProfileInfo
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            account={user?.account?.name}
                          />
                        </div>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Popover.Group>
          </Popover>

          <header className="py-10">
            <div className="max-w-9xl mx-auto px-4 sm:px-6 lg:px-8">
              {breadcrumbs && isUserInfoAvailable && (
                <div className="pb-10">
                  <Breadcrumb info={breadcrumbs} />
                </div>
              )}
              {header}
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div
            className={`max-w-9xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 print:px-20 ${
              wrapperClass ?? ''
            }`}
          >
            <div
              className={classNames(
                'bg-white rounded-lg shadow px-5 py-6 sm:px-6 print:shadow-none print:px-5',
                containerStyle || ''
              )}
            >
              {children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export { Header };
